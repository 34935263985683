import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Input from 'common/components/Input';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import GatsbyImage from 'common/components/GatsbyImage';
import { useForm, ValidationError } from '@formspree/react';
import Section, {
  BannerContentWrapper,
  BannerContent,
  Subscribe,
  Figure,
} from './banner.style';
import envelope from 'common/assets/image/webAppCreative/icons/envelope.png';

const Banner = () => {
  const dashboard = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/webAppCreative/map.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const [state, handleSubmit] = useForm("mzbqrgoa");

  if(state.succeeded) {
    return (
    <Section id="home">
        <Container width="1400px">
          <BannerContentWrapper>
            <BannerContent>
              <Heading
                className="animate__animated animate__fadeInUp"
                content="Add a lifeline to your deck ❤️"
              />
              <Text
                className="animate__animated animate__fadeInUp"
                content="Join deck lovers from around the world that use FindMyDeck for peace of mind"
              />
              <Heading as="h2" content="Thanks for signing up!" className="animate__animated animate__fadeInUp"/>
            </BannerContent>
            <Figure className="animate__animated animate__fadeInUp animate__fast">
              <GatsbyImage
                src={
                  (dashboard.illustration !== null) | undefined
                    ? dashboard.illustration.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="dashboard"
              />
            </Figure>
          </BannerContentWrapper>
        </Container>
      </Section>
    );
  } else {
    return (
      <Section id="home">
        <Container width="1400px">
          <BannerContentWrapper>
            <BannerContent>
              <Heading
                className="animate__animated animate__fadeInUp"
                content="Add a lifeline to your deck ❤️"
              />
              <Text
                className="animate__animated animate__fadeInUp"
                content="Join deck lovers from around the world that use FindMyDeck for peace of mind"
              />
              <Subscribe className="animate__animated animate__fadeInUp">
                <form className="signup-form"  onSubmit={handleSubmit}>
                  <Input

                    label="email"
                    inputType="email"
                    placeholder="Signup for launch dates and news"
                    iconPosition="left"
                    aria-label="email"
                    icon={<Image src={envelope} alt="envelope" />}
                  />
                  <ValidationError 
                    prefix="Email" 
                    field="email"
                    errors={state.errors}
                  />
                  <Button title="Subscribe" type="submit" disabled={state.submitting}/>
                </form>
              </Subscribe>
            </BannerContent>
            <Figure className="animate__animated animate__fadeInUp animate__fast">
              <GatsbyImage
                src={
                  (dashboard.illustration !== null) | undefined
                    ? dashboard.illustration.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="dashboard"
              />
            </Figure>
          </BannerContentWrapper>
        </Container>
      </Section>
    );
  }
};

export default Banner;
